const en_US_in_risks_page = {
  risks: 'Risks',
  of_using_ref_finance: 'of Using Ref. finance',
  introduction:
    'Providing liquidity and/or trading on Ref Finance do not come without risks. Before interacting with the protocol, please do research and understand the risks involved.',
  general_risks_quiz: 'General Risks Quiz',
  amm_core_design: 'AMM Core Design',
  audits: 'Audits',
  audits_paragraph_2:
    'Security audits do not eliminate risks completely. Please do not supply your life savings, or assets you cannot afford to lose, to Ref Finance, especially as a liquidity provider.',
  admin_keys: 'Admin keys',
  admin_keys_paragraph_2:
    'The current DAO has 26 members. Ref Finance will be transitioning to a fully decentralized DAO.',
  admin_keys_paragraph_3:
    'Please find below the list of contracts and addresses that have directly managed or currently manage the affairs of Ref Finance.',
  address: 'Address',
  type: 'Type',
  txid: 'TxID',
  funding: 'Funding',
  mission: 'Mission',
  Locked: 'Locked',
  exchange_contract: 'Exchange Contract',
  table_body_tr_1:
    'Manage the Automated Market Maker functions; Swap and Provide Liquidity',
  farming_contract: 'Farming Contract',
  table_body_tr_2: 'Manage liquidity incentives',
  staking_contract: 'Staking Contract',
  table_body_tr_3: 'Mint and burn xREF, and Distribute time-based rewards',
  sputnik_dao_contract: 'Sputnik DAO Contract',
  table_body_tr_4:
    'Ensure the success of Ref by taking strategic decisions (incl. smart contract amendments)',
  table_body_tr_5:
    'Manage and allocate funds to specific community contributors',
  table_body_tr_6: 'Execute the Strategy and Roadmap',
  vesting_contract: 'Vesting Contract',
  table_body_tr_7: 'Manage REF vesting contracts of Dev DAO members',
  airdrop_contract: 'Airdrop Contract',
  table_body_tr_8: 'Manage first REF airdrop',
  near_address: 'NEAR Address',
  table_body_tr_9: 'Manage one-time bug bounty payments',
  fungible_token_contract: 'Fungible Token Contract',
  table_body_tr_10: 'Mint REF token',
  rug_pull: 'Rug pull',
  rug_pull_paragraph_1:
    'If the team behind a token, either whitelisted or not, decides to abandon its project and takes the investors’ money, the project’s token will probably be worth $0.',
  rug_pull_paragraph_2:
    'If the token is whitelisted on Ref Finance, that does not mean the project will succeed. You are responsible for doing your own due diligence of the project and should understand that crypto are very-high risk, speculative investments.',
  rug_pull_paragraph_3:
    'You should be aware and prepared to potentially lose some or all of the money invested.',
  divergence_loss: 'Divergence Loss',
  divergence_loss_paragraph_1:
    'If you provide liquidity, please do note that you can make more money by not providing liquidity.',
  divergence_loss_paragraph_2:
    'Divergence Loss is often yet inappropriately called “impermanent loss”. The adjective (impermanent) may assume or create the marketing feeling that losses are only impermanent, meaning that losses are guaranteed to be reversed, which is not true.',
  divergence_loss_paragraph_3: 'Learn more about Divergence Loss',
  staking_risks: 'Staking risks',
  staking_risks_paragraph_1:
    'When staking you use multiple smart contract products each of which has its own risks.',
  permanent_loss_of_a_peg: 'Permanent loss of a peg',
  permanent_loss_of_a_peg_paragraph_1:
    'If one of the stablecoins in the pool goes significantly down below the peg of 1.0 and never returns to the peg, it will effectively mean that pool liquidity providers hold almost all their liquidity in that currency.',
  systemic_issues: 'Systemic issues',
  systemic_issues_paragraph_1:
    'In general, DeFi or the legos of money is highly connected, meaning that one failure of its component may trigger a succession of failures.',
  systemic_issues_paragraph_2:
    'A systematic risk means that you can lose money even if the failure does not directly concern your investment/exposure.',
  systemic_issues_paragraph_3:
    'The following risks may have an impact in the liquidity pools:',
  systemic_issues_paragraph_4: 'Smart contract issues with lending protocols',
  systemic_issues_paragraph_5: 'Smart contracts issues with staking protocols',
  systemic_issues_paragraph_6:
    'Systemic issues with the stablecoins in those pools',
  systemic_issues_paragraph_7:
    'Systemic issues with ERC20-native tokens in those pools',
  crypto_trading_addiction: 'Crypto trading addiction',
  crypto_trading_addiction_paragraph_1:
    'Trading crypto can be very addictive and, according to many sources, be a form of gambling addiction, which can destroy lives.',
  crypto_trading_addiction_paragraph_2:
    'Please find below a collection of stories relating to that matter.',
  crypto_trading_addiction_paragraph_3:
    "'Trading is gambling, no doubt about it'",
  crypto_trading_addiction_paragraph_4:
    "'I Lost Half a Million Pounds Trading Bitcoin'",
  crypto_trading_addiction_paragraph_5:
    "'We Spoke to a Therapist Who Treats Cryptocurrency Trading Addiction'",
  crypto_trading_addiction_paragraph_6:
    "'I lost millions through cryptocurrency trading addiction'",
  no_in_progress: 'No (In Progress)',
  yes: 'Yes',
  no: 'No',
  NA: 'N/A',
  ref_finance_near: 'ref-finance.near',
  ref_finance_near_mission:
    'v1 (depreciated)- Manage the Automated Market Maker functions; Swap and Provide Liquidity',
  token_ref_finance_near: 'token.ref-finance.near',
  token_ref_finance_near_type: 'Fungible Token Contract',
  token_ref_finance_near_mission: 'v1 (depreciated) - Mint REF token',
  refchef_near: 'refchef.near',
  simple_address: 'Simple Address',
  refchef_near_mission: 'Manage inter-account transactions',
  ref_dev_teller_near: 'ref-dev-teller.near',
  Locked_title: 'Locked? information box: when hovering to display:',
  Locked_paragraph_1:
    'Locked contract means that there are no access keys allowing the contract code to be re-deployed.',
  Locked_paragraph_2: 'In general case, the code can be re-deployed by:',
  Locked_paragraph_3: '1. a transaction with a deploy-code action',
  Locked_paragraph_4:
    '2. the contract itself can implement a function call that will trigger deploy-code action',
  Locked_paragraph_5:
    'To re-deploy the code with a transaction, the transaction has to be signed with a full-access key. If there is no such key on the contract, there is no way to re-deploy the code unless there is a dedicated support in the contract code itself, and thus we mark such contracts as locked.',
  Locked_paragraph_6:
    'If there is at least one full-access key registered on the contract account, the contract is not locked.',
  audited_first_sentence:
    'Ref Finance smart contracts have been <a rel="noopener noreferrer nofollow" target="_blank" href="https://guide.ref.finance/developers/audits" class="underline cursor-pointer text-riskTextColor hover:text-white"' +
    '>audited</a>.',

  admin_first_sentence:
    'Ref Finance is managed by the <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a>. There are <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://gov.ref.finance/t/introducing-the-guardians/253"' +
    '>Guardians</a>, specific NEAR addresses, which are able to pause the contract. Only the DAO can resume the contract, at any time.',
  risks_of_using_ref_finance:
    '<label class="text-greenColor">Risks</label> of Using Ref. finance',
  admin_sentence_1:
    'Ref Finance is managed by the  <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a> and will be transitioning to a fully decentralized DAO.',
  admin_sentence_2:
    'For more information relating to the contracts and addresses that have directly managed or currently manage the affairs of Ref Finance, please check our <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/contracts"' +
    '>Documentation</a>.',
  third_party_wallet: 'Third-party wallet',
  third_party_wallet_1: 'Ref Finance integrates third-party wallets.',
  third_party_wallet_2:
    'While we have taken measures to select wallet providers, we cannot guarantee their security or performance. You should familiarize yourself with the risks associated with the specific wallet provider and understand their terms of service, privacy policy, and security practices.',
  third_party_wallet_3:
    'Please use these services at your own risk and exercise caution.',
};
const en_US = {
  deposit: 'Deposit',
  Deposit: 'Deposit',
  withdraw: 'Withdraw',
  Withdraw: 'Withdraw',
  withdraw_token: 'Withdraw Token',
  swap: 'Swap',
  Swap: 'Swap',
  exchange_tokens: 'Exchange Tokens',
  pool: 'Pool',
  Pool: 'Pool',
  pools: 'Pools',
  Pools: 'Pools',
  Quiz: 'Quiz',
  no_tokens_deposited: 'No Tokens Deposited',
  view_pools: 'View Pools',
  view_pool: 'View Pool',
  add_token: 'Add token',
  Add_Token: 'Add Token',
  create_new_pool: 'Create new pool',
  Create_New_Pool: 'Create New Pool',
  your_liquidity: 'Your Liquidity',
  Your_Liquidity: 'Your Liquidity',
  farms: 'Farms',
  Farms: 'Farms',
  airdrop: 'Airdrop',
  Airdrop: 'Airdrop',
  docs: 'Docs',
  Docs: 'Docs',
  community: 'Community',
  Community: 'Community',
  more: 'More',
  More: 'More',
  account: 'Account',
  Account: 'Account',
  New_ui: 'New UI',
  Risks: 'Risks',
  Forum: 'Forum',
  Discord: 'Discord',
  Telegram: 'Telegram',
  Twitter: 'Twitter',
  Medium: 'Medium',
  move_assets_to_from_ethereum: 'Move assets to/from Ethereum',
  rainbow_bridge: 'Rainbow Bridge',
  deposit_to_swap: 'Deposit to Swap',
  connect_to_near: 'Connect to NEAR',
  slippage: 'Slippage tolerance',
  slippage_title: 'Transaction Settings',
  minimum_received: 'Minimum received',
  swap_rate: 'Swap rate',
  swap_rate_pre: 'Swap rate',
  pool_fee: 'Pool fee',
  balance: 'Balance',
  from: 'From',
  to: 'To',
  submit: 'Submit',
  save: 'Save',
  wrapnear: 'Wrap NEAR',
  wrapnear_tip_one: 'Wrapping NEAR allows you to trade on REF. Make sure to ',
  wrapnear_tip_two: 'leave 0.5 NEAR',
  wrapnear_tip_three: ' for gas fees.',
  wrap_error_msg: 'Not enough balance',
  sign_out: 'Sign Out',
  view_account: 'View Account',
  deposit_to_swap_and_add_liquidity: 'Deposit to swap and add liquidity',
  small_storage_fee_is_applied_of: 'Small storage fee is applied of',
  liquidity: 'Liquidity',
  tokens: 'Tokens',
  fee: 'Fee',
  fees: 'Fees',
  view_detail: 'View detail',
  pool_details: 'Pool details',
  details: 'Details',
  detail: 'Detail',
  liquidity_pools: 'Liquidity Pools',
  minimum_tokens_out: 'Minimum received',
  pair: 'Pair',
  tvl: 'TVL',
  search_pools: 'Search Pool',
  add_liquidity: 'Add Liquidity',
  remove_liquidity: 'Remove Liquidity',
  total_liquidity: 'Total liquidity',
  coming_soon: 'Coming soon',
  accumulated_volume: 'Accumulated Volume',
  underlying_liquidity: 'Underlying liquidity',
  total_shares: 'Total shares',
  my_shares: 'Shares',
  token: 'Token',
  enter_token_address: 'Enter token address',
  you_are_not_providing_liquidity_to_any_pools:
    'You aren’t providing liquidity to any pools',
  remove: 'Remove',
  select: 'Select',
  select_token: 'Select a token',
  basis_points: 'Basis points',
  total_fee: 'Total fee % ',
  protocol_fee_is: 'protocol fee is ',
  start_in: 'Start in',
  start_at: 'Start at',
  started_at: 'Started at',
  start_date: 'Start date',
  end_at: 'End at',
  end_date: 'End date',
  earn: 'Earn',
  ended: 'ENDED',
  pending: 'PENDING',
  pending_cap: 'Pending',
  pending_orders_title: 'Pending Close Orders',
  rewards_per_week: 'Rewards per week',
  apr: 'APR',
  total_staked: 'Total staked',
  unclaimed_rewards: 'Unclaimed rewards',
  claim: 'Claim',
  claim_all: 'Claim All',
  stake: 'Stake',
  unstake: 'Unstake',
  your_rewards: 'Your rewards',
  your_shares: 'Shares',
  shares_owned: 'Shares Owned',
  stake_your_liquidity_provider_LP_tokens:
    'Stake your Liquidity Provider (LP) tokens',
  go_to_wallet: 'Go to Wallet',
  recent_activity: 'Recent Activity',
  view_all: 'View All',
  cancel: 'Cancel',
  claim_ref_token: 'Claim Ref Token',
  has_no_available_claim: 'has no available claim',
  sorry: 'Sorry',
  total_ref_token: 'Total Ref Token',
  locking_ref_token: 'Locked Ref Token',
  unclaim_ref_token: 'Unclaimed Ref Token',
  unlocked: 'Unlocked',
  locking: 'Locked',
  you_can_claim_from: 'You can claim from',
  you_have_claimed_all_your_rewards: 'You have claimed all your rewards',
  ends_in: 'Ends in',
  days: 'days',
  swap_successful_click_to_view: 'Swap successful. Click to view',
  claim_successful_click_to_view: 'Claim successful. Click to view',
  claim_failed_click_to_view: 'Claim failed. Click to view',
  slippageCopyForMobile:
    'Slippage means the difference between what you expect to get and what you actually get due to other executing first',
  slippageCopy:
    'Slippage means the difference between what you expect to get and what you actually get due to other executing first',
  depositCopy:
    'This deposits your selected tokens into the <br> exchange for swapping or adding to a liquidity pool',
  nearDepositCopy:
    'This first wraps then deposits your Ⓝ into the <br> exchange for swapping or adding to a liquidity pool',
  nearWithdrawCopy:
    'This will first unwrap your Ⓝ then withdraw it from <br> the exchange and move the tokens to your wallet',
  withdrawCopy:
    'This will withdraw your selected tokens from <br> the exchange and deposit them into your wallet',
  registerToken:
    'This registers a new token with the <br> exchange that is not already listed',
  whitelistTokenCopy: 'Add any NEP-141 token',
  addLiquidityPoolCopy:
    'This creates a new liquidity pool for the two tokens selected. The fee is the percentage the pool takes from each transaction',
  swapCopy:
    'Swap exchanges the first selected token with the second <br> selected token.The pools with the highest available liquidity and the lowest exchange fee will be used',
  addTokenCopy: 'Add any NEP-141 token',
  getLPTokenCopy: 'Click here to jump to the corresponding pool',
  airdropCopy:
    ' Please claim your airdrop at least once before the expiration date otherwise your balance would be donated into the treasury',
  farmRewardsCopy: 'Indicative value based on prices and not actual execution',
  totalValueLockedCopy: 'Total Value Locked',
  topPoolsCopy: 'For every pair, only display the highest pool TVL',
  you_do_not_have_enough: "You don't have enough",
  must_provide_at_least_one_token_for: 'Must provide greater than 0 token for',
  is_not_exist: 'is not exist',
  must_input_a_value_greater_than_zero: 'Must input a value greater than 0',
  amount_must_be_not_greater_your_balance:
    'Amount must be not greater than your balance ',
  no_pool_available_to_make_a_swap_from:
    'No pool available to make a swap from',
  for_the_amount: 'for the amount',
  no_pool_eng_for_chinese: ' ',
  is_not_a_valid_swap_amount: 'is not a valid swap amount',
  not_nep_address:
    'The address you entered is not a NEP-141 address, please check and enter it again. If it is another mainnet contract, go to Rainbow Bridge for bridging processing',
  not_correct_address:
    'The address you entered is incorrect, please check and enter',
  popular_tokens: 'Common Tokens',
  asset_label: 'Asset',
  account_label: 'Account',
  total_label: 'Total',
  top_pools: 'Top pools',
  pool_id: 'Pool ID',
  more_pools: 'More pools',
  my_watchlist: 'My Watchlist',
  h24_volume: '24h volume',
  volume: 'Volume',
  hide_low_tvl_pools: 'Hide low TVL pools',
  hide_low_tvl_pools_mobile: 'Hide low TVL',
  watchlist_title: 'My watchlist on the top',
  add_watchlist: 'Add Watchlist',
  remove_watchlist: 'Remove Watchlist',
  my_watchlist_copy: 'Marked pools will appear here',
  search_token: 'Search token',
  language: 'Language',
  deposit_to_add_liquidity: 'Deposit to Add Liquidity',
  slip_warn: 'Be careful, please check the minimum you can receive',
  slip_invalid: 'The slippage tolerance is invalid',
  input_to_search: 'Input to search',
  click_search_bar_to_search: 'Click search bar to search',
  reward_tokens: 'Reward tokens',
  detail_tip: 'Detail',
  no_data: 'No Data',
  stable_swap: 'StableSwap',
  StableSwap: 'StableSwap',
  exchange_rate: 'Exchange rate',
  including_fees: '(including fees)',
  standard_gas: 'Standard Gas fee:',
  rst_token: 'Your RST token',
  remove_tip: 'No fee in removing liquidity by share',
  share_liquidity: 'Share of liquidity',
  remove_token_confirm: 'You will remove RUST token',
  remove_as_one_token: 'Remove as one token',
  flexible_tip: 'Remove how much you want per token',
  add_type_all: 'Add all tokens in a balanced proportion',
  add_type_max: 'Use maximum amount of tokens available',
  select_balance: 'Select balance',
  selectBalanceCopy:
    'Select [NEAR wallet] to swap directly tokens available in your NEAR wallet',
  near_wallet: 'NEAR wallet',
  ref_account: 'REF account',
  swap_tip: 'Swap from your REF account by selecting REF account in settings',
  attention: 'Attention',
  live: 'Live',
  ended_search: 'Ended',
  my_farms: 'Your Farms',
  staked_only: 'Staked',
  sort_by: 'Sort by',
  your_farms_rewards: 'Your Farms Rewards',
  your_farms: 'Your farms',
  new: 'Latest',
  unstake_tip_t: 'you have rewards unclaimed...',
  unstake_tip_m:
    'Unstaking will remove the stake from all active and pending farms of the same pair.',
  unstake_tip_b: 'Do you want to unstake?',
  value_rewards: 'Claimed rewards',
  stake_unstake_tip:
    'Staking or unstaking will automatically claim your rewards.',
  over_tip: 'To optimise gas fee, you can withdraw up to 4 tokens at a time',
  no_token_tip: 'No reward token',
  getToken_tip: 'You can claim your rewards or stake your LP Tokens now!',
  in_farm: 'in Farm',
  swap_rate_including_fee: 'Swap rate (including fees)',
  by_share: 'By Share',
  by_token: 'By Token',
  your_positions_will_be_displayed_here:
    'Your positions will be displayed here.',
  price_impact: 'Price impact',
  shares_left: 'Shares left',
  total_stable_coins: 'Total stablecoins',
  daily_volume: 'Daily volume',
  liquidity_utilisation: 'Liquidity utilisation',
  token_reserves: 'Stats',
  roi_calculator: 'ROI Calculator',
  lp_staked: 'LP Tokens Staked',
  usd: 'USD',
  lp_token: 'LP Tokens',
  stake_for: 'Duration',
  day_1: '1D',
  day_2: '7D',
  day_3: '30D',
  day_4: '90D',
  day_5: '1Y',
  cur_apr: 'ROI',
  reward_token: 'Reward tokens',
  get_lp_token: 'Get LP Tokens',
  calculate_roi: 'ROI calculator',
  rewards_claimed: 'Rewards claimed',
  value_rewards_token: 'Value of rewards',
  all: 'All',
  all_5: 'Max 4 tokens at a time',
  insufficient_shares: 'Insufficient shares',
  shares_removed: 'Shares',
  shares_avaliable: 'Shares avaliable',
  minimum_shares: 'Minimum shares',
  shares_tip: 'Shares available / Total shares',
  stablecoin_only: 'Stablecoin only',
  more_than: 'Please input number that more than or equal to 0.01',
  less_than: 'Please input number that less than 20',
  no_valid: 'Please input valid number',
  lp_fee: 'LP fee',
  protocol_fee: 'Protocol fee',
  referral_fee: 'Referral fee',
  protocol_fee_referral_fee: 'Protocol fee and Referral fee',
  total_fee_create: 'Total fee',
  xref_title: 'EARN MORE BY',
  xref_title1: 'Earn more',
  xref_title2: 'by staking REF for xREF',
  xref_introdution:
    'By staking REF, you have the opportunity to earn fees generated by the protocol. Any REF holders can have a share in the revenue earned by Ref Finance.',
  staking_apr: 'Staking APR',
  view_stats: 'View Stats',
  xref: 'xREF',
  ref: 'REF',
  more_than_general_seed: 'Input must be greater than or equal to 0.000001',
  more_than_stable_seed: 'Input must be greater than or equal to 1',
  are_you_sure: 'Are you sure',
  price_impact_is_about: 'Price impact is about',
  yes_swap: 'Yes, swap',
  amount_must_be_greater_than_0: 'amount must be greater than 0',
  volume_ratio: '24h Volume/ Liquidity ratio',
  go_to_near_wallet: 'Go to NEAR Wallet',
  into_ref_account: 'into your REF account.',
  deposit_into_ref_account: 'into your REF account to add liquidity',
  deposit_near_tip: 'To deposit, keep at least 1 NEAR to cover gas fee',
  input_greater_than_available_shares: 'Input greater than available shares',
  number_of_unique_stakers: 'Number of Unique Stakers',
  total_ref_staked: 'Total REF Staked',
  total_xref_minted: 'Total xREF Minted',
  protocol_projected_revenue: 'Revenue Shared with xREF Holders',
  total_fee_Revenue_shared_with_xref_holders:
    'Total Fee Revenue Shared with xREF Holders',
  provision_treasury: 'Provision Treasury',
  protocol_projected_revenue_tip:
    'This number corresponds to the cumulative shared trading fee revenue to xREF holders. It is equal to 75% of the total platform fee revenue. It will differ from the actual REF token buyback amount due to price fluctuations.',
  stake_ref_to_xref_for_earning_more: 'Stake REF to xREF for earning more!',
  auto_router: 'Auto Router',
  auto_router_detail_sub_1: 'This route optimizes your swap by',
  auto_router_detail_sub_2: 'considering all pools for the same pair.',
  auto_router_detail_sub_3: 'Details:',
  introducing_parallel_swap: 'Introducing Parallel Swap',
  at_least: 'At least',
  xref_start_time: 'Starts on 1am UTC, 18th Jan, 2022.',
  xref_to_receive: 'xREF to receive',
  ref_to_receive: 'REF to receive',
  more_expensive_than_best_rate_en: 'more expensive than the best rate',
  optimal_path_found_by_our_solution: 'Optimal path found by our solution',
  check_to_stable_banner: 'Check',
  rates_to_stable_banner: 'Rates',
  make_sure_you_understand_what_you_do: 'Make sure you understand what you do',
  go_to_your_account_to: 'Go to your account to',
  deposit_l: 'deposit',
  withdraw_l: 'withdraw',
  got_it: 'Got it',
  check_the_transaction_settings: 'Check the transaction settings',
  filter_by: 'Filter by',
  allOption: 'All',
  stablecoin: 'Stable',
  near_ecosystem: 'Near-eco',
  bridged_tokens: 'Bridged',
  gaming: 'Gaming',
  nft: 'NFT',

  bridge: 'Bridge to/from',
  from_ethereum: 'Ethereum',
  from_aurora: 'Aurora',
  from_solana: 'Solana',
  from_terra: 'Terra',
  from_celo: 'Celo',
  no_result: 'Sorry, we couldn’t find any farm',
  multiple: 'Multiple',
  default: 'Default',
  vi_go_live: 'Vietnamese goes live!',
  do_not_need_to_deposit_in_ref:
    "You don't need to deposit in Ref Finance anymore!",
  you_can_add_liquidity_directly:
    'You can now add liquidity directly using your NEAR wallet.',
  do_not_need_deposit_anymore: "You don't need to deposit anymore!",
  lightning_swaps: 'Lightning Swaps',
  frictionless_add_remove_liquidity:
    'Frictionless Add/Remove Liquidity Actions',
  create: 'Create',
  select_a: 'Select a',
  wallet: 'wallet',
  Wallet: 'Wallet',
  to_use_ref_finance: 'to use Ref.Finance',
  not_supported: 'not supported',
  installed: 'installed',
  installe_now: 'install now',
  extension: 'extension',
  first_time_using_ref: 'First time using Ref',
  learn_more: 'Learn more',
  install_sender_now: 'Install Sender Now',
  connect_to_dapps_with_one_click: 'Connect to dApps with one click',
  install: 'install',
  Connecting: 'Connecting',
  check_sender_wallet_extension: 'Please check Sender wallet extension',
  having_trouble: 'Having trouble?',
  go_back: 'Go back',
  web: 'web',
  Type: 'Type',
  click_to_view: 'Click to view',
  transaction_failed: 'Transaction failed',
  wallet_vi: ' ',
  value: 'Value',
  usd_value: 'USD Value',
  sauce_note:
    'SAUCE is designed for liquidity pools with pegged assets, delivering optimal prices.',
  support_ledger: 'Support Ledger',
  initialize_account_tip: 'Please initialize your account.',
  support_ledger_tip:
    "By design, Ledger cannot handle large transactions (i.e. Auto Router: trade across multiple pools at once) because of its memory limitation. When activated, the 'Support Ledger' option will limit transactions to their simplest form (to the detriment of potential optimal prices found by our Auto Router), so transactions of a reasonable size can be signed.",
  start: 'Start',
  aprTip: 'Indicative value based on all rewards (pending and running)',
  half: 'Half',
  max: 'Max',
  ref_account_balance_tip:
    'It seems like an error occurred while adding/removing liquidity to the pool',
  ref_account_tip_2: 'You have token(s) in your REF Account',
  ref_account_tip_top: 'your token(s) may be now in your REF inner account',
  click_here: 'Click here',
  to_recover_them: 'to recover them',
  ref_account_tip_3:
    'To withdraw token(s) from your REF Account to your NEAR Wallet, please select and withdraw',
  aurora_account_tip_4:
    'To withdraw token(s) from your Mapping Account to your NEAR Wallet, please select and withdraw',
  pool_fee_cross_swap: 'Pool/Cross-chain fee',
  mapping_account: 'Mapping Account',
  mapping_account_tip: 'You have token(s) in Mapping Account',
  cumulative_ref_buyback: 'Cumulative REF Buyback',
  yearly_revenue_booster: 'Yearly Revenue Booster',
  Confirm: 'Confirm',
  Request_for_Quote: 'Request for Quote',
  mapping_account_explanation:
    "A mapping address is a 'proxy address', which maps the user's NEAR account with a corresponding ETH address on Aurora.",
  buy: 'Buy',
  sell: 'Sell',
  buy_nsn: 'Buy USN',
  buy_nsn_tip: 'USN is the native stablecoin backed by the NEAR economy',
  usn_tip_one: 'USN allows you to trade on REF. Make sure to ',
  rate: 'Rate',
  name: 'Name',
  diff: 'Diff',
  best: 'Best',
  trading_fee: 'Trading fee',
  usn_successful_click_to_view: 'Trading successful. Click to view',
  awesomeNear_verified_token: 'AwesomeNear Verified Token',
  usn_fee_tip: 'Zero-fee charged by Ref, users only pay USN fee!',
  total_bitcoins: 'Total Bitcoins',
  total_usd_value: 'Total USD value',
  bitcoin_value: 'Bitcoin Value',
  stable_coin_value: 'StableCoin Value',
  pool_fee_apr: 'Pool fee APY',
  ref_account_balance_tip_mobile: 'You have tokens in your REF account.',
  click: 'Click',
  to_recover: 'to recover.',
  rewards_week: 'Rewards/week',
  rewards: 'Rewards',
  claimed_Rewards: 'Claimed Rewards',
  POOL: 'POOLS',
  STAKE: 'STAKE',
  lp_Token: 'LP Tokens',
  booster: 'Booster',
  slash_policy: 'Slash Policy',
  stake_period: 'Stake Period',
  append: 'Append',
  append_staking: 'Append Staking',
  unstake_time: 'Unstake Time',
  farm: 'Farm',
  reward_Token: 'Reward Token',
  status: 'Status',
  value_of_rewards: 'Value of Rewards',
  Rewards: 'Rewards',
  free: 'Free',
  lp_tokens: 'LP tokens',
  connect_to_wallet: 'Connect Wallet',
  anytime: 'Anytime',
  slash_policy_content:
    'Each seed has its own slash rate. And it will decreases linearly over time until be 0 at the unlock time of this CD Account.',
  not_expired: 'Not expired',
  accept_pay_slash_tip: 'I accept to pay slash',
  i_will_pay: 'I will pay',
  lp_token_slash: 'LP token exit fee.',
  booster_change_reason: 'Booster will change due to appending time',
  initial_booster: 'Initial Booster',
  append_timing: 'Append timing',
  cd_limit: 'more than 16 cd account limit',
  expired: 'Expired',
  all_5_v2: '5 tokens/time',
  all_4_v2: '4 tokens/time',

  more_than_seed: 'Input must be greater than or equal to ',
  how_to_earn_more: 'How to compound rewards?',
  you_shares: 'Your Shares',
  lock_your_lp_tokens_with_booster: 'Lock your LP tokens for boosted rewards',
  unstaked_anytime_no_booster: 'Unstaked anytime, no booster',
  ordinary_stake: 'Ordinary Stake',
  locking_stake: 'Lock-up staking',
  change_to_lock: 'Change to Lock-up staking',
  estimated_rewards: 'Estimated rewards',
  final_booster: 'Final Booster',
  existing_amount: 'Existing amount',
  append_amount: 'Append amount',
  will_be_able_to_unstaked_after: 'will be able to unstaked after',
  expected_reward: 'Expected reward',
  you_have: 'You have',
  tokens_small: 'tokens',
  reward_apr: 'Rewards APR',
  price_board: 'Price board',
  close: 'Close',
  stake_min_deposit: 'Input must be greater than or equal to ',
  unlock: 'Unlock',
  exit_fee: 'Exit fee',
  end_locking_period: 'End of locking period',
  add_lp_tokens_tip:
    'You need LP tokens to stake into the corresponding farm. First, add liquidity to the pool to get LP tokens.',
  slash_tip: 'Exit fee = Exit fee rate * End of locking period * Unlock amount',

  nonBoosted: 'Non-boosted',
  exit_fee_tip: 'Estimated amount you would pay to break your lock-up period',
  forbiddenTip: 'This farm does not support this duration option',
  unstakeTip: 'Staking or unstaking will automatically claim your rewards.',
  endedFarmsUnstakeTip:
    'Unstaking will remove the stake from all active and pending farms of the same pair. ',
  comimg: 'Coming',
  appendTip: 'Cannot be earlier than current locking time',
  week: 'week',
  yours: 'Yours',
  boost: 'Boost',
  near: 'NEAR',
  eth: 'ETH',
  others: 'Others',
  stakeLove: 'Stake LOVE',
  unstakeLove: 'Unstake LOVE',
  get_love: 'Get LOVE',
  swap_successful: 'Swap successfully. ',
  trading_successfull: 'Trading successful. ',
  total_near_amount: 'Total NEAR amount',
  near_value: 'NEAR Value',
  borrow: 'Borrow',
  burrow_usn_tip: 'You can borrow USN on Burrow.',
  migrate: 'Migrate',
  go_to_new_farm: 'Go to New Farms',
  voting_gauge_share_tip:
    'The proportion of the REF used for voting allocation to the total REF reward in this period',
  swap_capital: 'SWAP',
  farm_capital: 'FARMS',
  sauce_capital: 'SAUCE',
  dao_capital: 'DAO',
  available_to_stake: 'available to stake',
  recent_one_mounth_activity: 'One Month Activities',
  your_assets: 'Your Assets',
  connect_wallet: 'Connect Wallet',
  disconnect: 'Disconnect',
  change: 'Change',
  wallets_below_supports_on_PC: 'Wallets below supports on PC',
  connect: 'Connect',
  make_sure_device_plugged_in: 'Make sure your device is plugged in',
  then_enter_an_account_id_to_connect: 'then enter an account ID to connect',
  veTip: 'veLP tokens are not transferable, veLPT represent your voting power ',
  loveTip:
    'LOVE stands for Liquidity Of veToken. LOVE, like any NEP-141 token, is transferable and can be swapped, pooled, staked and farmed. LOVE represents the underlying liquidity of your veTokens',

  lock_your_lp_tokens: 'Lock Your LP Tokens',
  unlock_your_defi_power: 'Unlock Your DeFi Power',
  unlock_tip_mobile: 'Unlocking is available now!',
  unlock_tip_top: 'Your locking has been expired, unlocking is available now!',
  bonus_to_be_withdraw: 'bonus to be withdraw',
  keep_ve: 'keep',
  months: 'months',
  month: 'month',
  lock_lp_tokens: 'Lock LP Tokens',
  get_lptoken: 'Get LP Tokens',
  duration: 'Duration',
  ve_lock_tip: 'Cannot be earlier than current unlocking date',
  get: 'Get',
  locking_ve: 'Locking',
  in_addition_to_the: 'In addition to the',
  already_locked: 'already locked',
  unlocking_is: 'Unlocking is',
  you_have_no_lp_share: 'You have no LP Tokens',
  lock: 'Lock',
  unlock_lptoken: 'Unlock LP Tokens',
  locked: 'Locked',
  available: 'Available',
  balance_lowercase: 'balance',
  voting_power: 'Voting Power',
  lock_lp_tokens_first: 'Lock LP Tokens first!',
  farm_booster: 'Farm Booster',
  go_to_farm: 'Go to Farm',
  in: 'in',
  more_lowercase: 'more',
  avaliable_to_lock: 'Available to lock',
  lock_lptoken: 'Lock LP Tokens',
  lptoken_locker: 'LP Token Locker',
  filter: 'Filter',
  bonus: 'Bonus',
  your_shares_ve: 'Your Shares',
  voted: 'Voted',
  my_voting_power: 'My Voting Power',
  new_voting_ratio: 'New Voting Ratio',
  REF_allocation: 'REF allocation',
  vote: 'Vote',
  preview: 'Preview',
  options: 'options',
  ratio: 'Ratio',
  forum_discussion: 'Forum Discussion',
  voted_veLPT: 'Voted veLPT',
  your_contribution: 'Your contribution',
  delete: 'Delete',
  not_start: 'Not start',
  no_veLPT: 'No veLPT',
  claim_bonus: 'Claim Bonus',
  ended_ve: 'Ended',
  proposal_details: 'Proposal Details',
  back: 'Back',
  pending_ve: 'Warming up',
  you_voted: 'You voted',
  add_bonus: 'Add Bonus',
  you_vote: 'You vote',
  Creator: 'Creator',
  turn_out: 'Turnout',
  top_answer: 'Top Answer',
  your_bonus: 'Your bonus',
  gauge_weight_vote: 'Gauge Weight Vote',
  governance: 'Governance',
  confirm: 'Confirm',
  voting_period: 'Voting Period',
  applying_period: 'Applying Period',
  community_gauge_share: 'Community Gauge Share',
  qualified_pools: 'Qualified Pools',
  total: 'Total',
  supply_voted: 'Supply voted',
  proposed: 'Proposed',
  farm_reward: 'Farm Reward',
  check_last_round: 'Check Last Round',
  last_round_voting_result: 'Last Round Voting Result',
  create_proposal: 'Create Proposal',
  type: 'Type',
  add_option: 'Add Option',
  lock_lp_terms:
    "I understand that I won't be able to remove my LP Tokens for the entire duration of the agreed locking period",
  connect_to_near_wallet: 'Connect to NEAR Wallet',
  only_for_whitelist_address: 'Only for whitelist address',
  v1Legacy: 'Legacy',
  v2New: 'Classic-New',
  v2_new_farms: 'Classic farms update',
  migrate_now: 'Migrate Now!',
  v2_boost_tip:
    'The updated classic farms will support boosted farms. The Legacy farms will run dry of rewards on the <span class="font-bold">1st of August 2022</span>.',
  v2_boost_tip2: 'The updated classic farms will support boosted farms.',
  v2_boost_no_tip:
    'The Legacy farms will run dry of rewards on the <span class="font-bold">1st of August 2022</span>.',
  no_farm_need_to_migrate: 'No Farm need to Migrate',
  v1_farms: 'Legacy Farms',
  has_rewards_tip:
    'You have claimed rewards in <a rel="noopener noreferrer nofollow" class="text-greenColor text-base cursor-pointer underline hover:text-senderHot"  href="/farms" >Legacy Farms</a>. You can withdraw them at any time.</div>',
  love: 'LOVE',
  Available_to_stake: 'Available to stake',
  you_staked: 'You staked',
  has_rewards_tip_in_v2:
    'Withdraw legacy rewards from <a rel="noopener noreferrer nofollow" href="/farms" class="text-sm text-greenColor cursor-pointer underline ml-1 hover:text-senderHot"> Legacy Farms</a>.',
  how_to_get: 'How to get?',
  get_booster: 'Get Booster',
  ended_farms: 'ended farms',
  boosted_farms: 'Boosted farms',
  your_farm: 'Your farm',
  your_boosted_farms: 'Your boosted farms',
  search_farms_by_token: 'Search farms by token',
  search_farms: 'Search farms',
  more_lock_ve_popup: 'more ',
  more_lock_ve_popup2: 'more',
  more_in: 'more in',
  starts: 'starts',
  left: 'left',
  proposal_title: 'Proposal Title',
  share_forum_discussion_link_here: 'Share forum discussion link here',
  required_field: 'Required field',
  voting_power_and_extra_earnings: 'Voting power & Extra earnings',
  bonus_tip:
    'Voting bonus is designed to encourage users to vote. Your bonus depends on your number of shares',
  yes_continue: 'Yes, continue',
  unlocking_tip:
    'Unlocking LP Tokens will automatically cancel your vote (and associated bonus) in the following proposal(s)',
  create_pool: 'Create Pool',
  search_by_token: 'Search by token',
  your_power: 'Your Power',
  farm_has_boost_tip:
    'Your Power = Your staked LP Tokens * booster (by staking LOVE)',
  farm_no_boost_tip: 'Your Power = Your staked LP Tokens',
  more_than_a_simple_pool: 'More than a simple pool',
  ended_Farms: 'Ended Farms',
  vote_capital: 'VOTE',
  starting_time: 'Starting Time',
  ending_time: 'Ending Time',
  please_choose_voting_duration: 'Please choose voting duration',
  please_choose_proposal_voting_duration:
    'Please choose proposal voting duration',
  days_ve: 'days',
  vote_mobile_capital: 'VOTE',
  risks_capital: 'RISKS',
  view_on: 'View on',
  boostFarmTip: 'Get booster by staking LOVE',
  boosted: 'Boosted',
  love_staked: 'Staked LOVE',
  your_apr: 'Your APR',
  range_apr: 'Range or reference APR',
  no_pair_found: 'No pair found',
  found_stable_pool_in_sauce: 'Found stable pool in SAUCE',
  lp_tokens_locking: ' LP Tokens ',
  you_currently_have: 'You currently have ',
  scheduled_to_be_unlocked: 'scheduled to be unlocked',
  locking_more_lp_tokens: 'Locking more LP Tokens ',
  will_mean_these: 'will mean these ',
  lp_tokens_will_not_be_unlocked_until: ' LP Tokens will not be unlocked until',
  "I_understand_that_I_won't_be_able_to_remove_my":
    "I understand that I won't be able to remove my ",
  until: 'until',
  continue: 'Continue',
  then_enter_a_derivation_path_to_connect:
    'then enter a derivation path to connect',
  account_identification_failed:
    'Account identification failed! Please provide manually',
  input_account_id: 'Input account ID',
  we_found: 'We found ',
  accounts_on_your_device: 'accounts on your device',
  select_the_accounts_you_wish_to_connect:
    'Select the account you wish to connect',
  can_not_find_any_accounts:
    "Can't found any account associated with this Ledger. Please create a new NEAR account on",
  or_connect_another_ledger: 'or connect an another Ledger',
  add: 'Add',
  finish: 'Finish',
  add_custom_network: 'Add Custom Network',
  network_name: 'Network Name',
  rpc_name_taken_tip: 'The network name was already taken',
  network_invalid: 'The network was invalid',
  fobidden_testnet_rpc_tip:
    "RPC server's network (testnet) is different with this network(mainnet)",
  no_support_testnet_rpc_tip: 'Testnet does not support adding custom RPC',
  stable: 'Stable',
  trade_capital: 'TRADE',
  trade_capital_hump: 'Trade',
  pro: 'Pro',
  limit: 'Limit',
  make_an_order: 'Make an order',
  your_active_orders_will_appear_here: 'Your active orders will appear here',
  your_history_orders_will_appear_here: 'Your history orders will appear here',
  liquidity_capital: 'LIQUIDITY',
  goes_to_limit_order: 'goes to limit order.',
  farm_only: 'Farm only',
  please_confirm_this_transaction_on_ledger:
    'Please confirm this transaction on Ledger',
  limit_order: 'Limit Order',
  newText: 'New',
  farm_stop_tip: 'This farm has been stopped.',
  pool_stop_tip: 'This pool has been stopped.',
  volume_24h: 'Volume (24h)',
  add_to_watchlist: 'Add to watchlist',
  remove_from_watchlist: 'Remove from watchlist',
  create_order: 'Create Order',
  filled: 'Filled',
  canceled: 'Canceled',
  partially_filled: 'Partially Filled',
  pool_composition: 'Pool Composition',
  amount: 'Amount',
  h24_volume_bracket: 'Volume(24h)',
  fee_24h: 'Fee(24h)',
  cross_chain_swap: 'Cross-chain Swap',
  orders: 'Orders',
  your_orders: 'Your Orders',
  active: 'Active',
  history: 'History',
  you_sell: 'You Sell',
  you_buy: 'You Buy',
  fee_tiers: 'Fee tiers',
  order_rates: 'Order rates',
  created: 'Created',
  executed: 'Executed',
  actions: 'Actions',
  claimed_upper: 'Claimed',
  open_my_order: 'Open',
  initial_order: 'Initial Order',
  this_order_has_been_partially_filled: 'This order has been partially filled',
  filled_via_swap: 'Filled via swap',
  state: 'State',
  partially: 'Partially',
  near_validation_error: 'Must have 0.5N or more left in wallet for gas fee.',
  near_min_validation_error:
    'Must have 0.2N or more left in wallet for gas fee.',
  no_positons_in_this_pool_yet: 'No positons in this pool yet',
  no_claimed_rewards_yet: 'No claimed rewards yet',
  out_of: 'out of',
  value_must_be_less_than_or_equal_to: 'Value must be less than or equal to',
  select_tokens: 'Select Tokens',
  fee_Tiers: 'Fee Tiers',
  select_s: 'select',
  no_pool: 'No Pool',
  input_amount: 'Input Amount',
  update_range: 'Update Range',
  not_enough_balance: 'Not Enough Balance',
  set_price_range: 'Set Price Range',
  current_price: 'Current Price',
  pool_creation: 'Pool creation',
  pool_creation_tip:
    'There is no existing pool for the selected tokens. To create the pool, you must set the pool rate, by providing the corresponding amount for each token.',
  starting_price: 'Starting Price',
  create_a_pool: 'Create a Pool',
  add_single_price_tip:
    'As long as the market is trading into your range of prices, you will receive trading fees proportional to your liquidity.',
  invalid_range_tip:
    'Invalid range selected. The min price must be lower than the max price.',
  maket_price_outside_tip: 'The maket price is outside your price range.',
  maket_price_outside_single_only_tip:
    'The market price is outside the price range. Add liquidity for the corresponding token.',
  min_price: 'Min Price',
  max_price: 'Max Price',
  min: 'Min',
  out_of_range: 'Out of range',
  in_range: 'In range',
  unclaimed_fees: 'Unclaimed Fees',
  your_position_will_be: 'Your position will be',
  at_this_price: 'at this price',
  price_range: 'Price Range',
  positions: 'Positions',
  position: 'Position',
  add_new_position: 'Add New Position',
  range: 'Range',
  unclaimed_fee: 'Unclaimed Fee',
  login_risk_tip:
    'By checking this box and moving forward, you confirm that you fully understand the <a rel="noopener noreferrer nofollow" target="_blank" class="text-greenColor text-sm font-bold cursor-pointer hover:underline" href="/risks"' +
    ')">risks</a> of using Ref Finance.',
  no_pool_tip: 'Oops! The pool does not exist.',
  total_ref_week: 'Total REF/week',
  ref_week_you_will_get: 'REF/week you will get',
  stable_pool: 'Stable Pool',
  market_rate: 'Market Rate',
  limit_price_tip:
    'To improve deal the efficiency, your price should be in one slot nearby automatically',
  no_token_found: 'No token found',
  search_name_or_address: 'Search name or paste address...',
  token_address_invalid: 'The token address was invalid',
  price_impact_warning: 'Price Impact Warning',
  confirm_order: 'Confirm Order',
  you_Sell: 'You Sell',
  to_Buy: 'to Buy',
  at_Price: 'at Price',
  insufficient_balance: 'Insufficient Balance',
  MORE: 'MORE',
  limitTip: 'Your price is automatically set to the closest price slot',
  v2PoolTip: 'You can have up to 16 positions in DCL pools',
  orderTip: 'You can have up to 16 active/history limit orders',
  v2_pools: 'DCL Pools',
  minimum_received_dot: 'Minimum received',
  v2_your_pool_introduction: 'Your discretized concentrated liquidity',
  v1_your_pool_introduction: 'Your liquidity in classic pools',
  estimate_value: 'Estimate Value',
  price: 'Price',
  '@price': '@Price',
  h24_high: '24h High',
  h24_low: '24h Low',
  h24_Volume: '24h Volume',
  orderbook: 'Order Book',
  orderbook_mobile: 'Orderbook',
  trades: 'Trades',
  qty: 'Qty',
  total_orderly: 'Total',
  time: 'Time',
  balances: 'Balances',
  asset: 'Asset',
  wallet_up: 'Wallet',
  account_in_order: 'Account: in Order',
  source_address: 'Source Address',
  action: 'Action',
  market: 'Market',
  order_type: 'Order Type',
  order_type_limit_tip:
    'A limit order is an order to buy or sell at a specific price, or better. Limit orders are not guaranteed to execute',
  order_type_market_tip:
    'A market order is immediately matched to the best available market price, and executed',
  quantity: 'Quantity',
  advanced: 'Advanced',
  tip_fok:
    'Fill-Or-Kill is an order to buy or sell that must be executed immediately in its entirety; otherwise, the entire order will be cancelled.',
  tip_post_only:
    'Post Only ensures that traders can only place an order if it would be posted to the orderbook as a Maker order. An order which would be posted as a Taker order will be cancelled.',
  tip_ioc:
    'Immediate-Or-Cancel is an order to buy or sell that must be filled immediately. Any portion of an IOC order that cannot be filled will be cancelled.',
  Taker: 'Taker',
  Maker: 'Maker',
  see_all: 'See all',
  h24Vol: '24h Vol',
  h24Range: '24h Range',
  chart: 'Chart',
  deposit_assets_to_begin_your_trading_journey:
    'Deposit assets to begin your {br} trading journey.',
  open_orders: 'Open Orders',
  ref_order_only: 'Order on REF only',
  ref_order: 'REF Dex only',
  orderly_portfolio_table_tips:
    'The data provided herein includes all assets and records in your account, not limited to those generated through REF.',
  orderly_ws_refresh: 'Please refresh the data to continue',
  refresh: 'Refresh',
  settled_balance: 'Settled Balance',
  settled_amount: 'Settlement Amount',
  Side: 'Side',
  all_side: 'All Side',
  both_side: 'Both Side',
  both: 'Both',
  filled_qty: 'Filled / Qty',
  fill_qty: 'Fill / Qty',
  est_total: 'Est.Total',
  avg_price: 'Avg.Price',
  create_time: 'Create Time',
  spot: 'Spot',
  futures: 'Futures',
  All: 'All',
  Filled: 'Filled',
  Fees: 'Fees',
  Cancelled: 'Cancelled',
  Rejected: 'Rejected',
  all_instrument: 'All Instrument',
  available_orderly: 'Available',
  history_orderly: 'History',
  settle_pnl: 'Settle PnL',
  settle_pnl_tips:
    'By doing this, we’ll move your profit or loss from perp markets into the USDC.e token balance. This has no impact on your open positions or health.',
  total_unsettled_pnl: 'Total unsettled PnL',
  fut_unreal_pnl: 'Fut. Unreal. PnL',
  fut_daily_real: 'Fut. Daily Real.',
  fut_notional: 'Fut. Notional',
  fut_unsettle_pnl: 'Unsettle PnL',
  settle: 'Settle',
  mark_orderly: 'Mark',
  avg_open: 'Avg. Open',
  liq_price: 'Liq. Price',
  unreal_pnl: 'Unreal. PnL',
  daily_real: 'Daily Real',
  market_price: 'Market Price',
  NEW: 'New',
  PARTIAL_FILLED: 'Partial Filled',
  limit_price: 'Limit Price',
  unsettled_pnl: 'Unsettled PnL',
  notional: 'Notional',
  assets: 'Assets',
  limit_orderly: 'Limit',
  'qty.': 'Qty.',
  no_orders_found: 'No orders found',
  no_records_found: 'No records found',
  its_empty: "It's Empty!",
  current_orderly: 'Current',
  cancel_order: 'Cancel Order',
  post_only: 'Post Only',
  fok: 'FOK',
  ioc: 'IOC',
  instrument: 'Instrument',
  open_order_detail: 'Open Order Detail',
  history_order_detail: 'History Order Detail',
  select_orderly: 'Select ',
  change_orderly: 'Change',
  token_orderly: 'Token',
  user_foot_tip:
    '* This Orderbook page is a graphical user interface for trading on Orderly Network, and is provided as a convenience to users of Ref Finance.',
  learn_more_orderly: 'Learn more',
  more_order_book_page_detail:
    'This Orderbook page is powered by Orderly Network, users are strongly encouraged to do their own research before connecting their wallet and/or placing any orders.{br} Ref Finance does not guarantee the security of the systems, smart contracts, and any funds deposited or sent to those systems and contracts.{br} Neither Ref Finance nor Orderly Network is responsible for any profit or loss of investment users made through this Orderbook page.',
  learn_more_about: 'Learn more about',
  learn_more_about_zh: ' ',
  by_click_confirm:
    'By clicking "Confirm", you confirm that you have comprehensively reviewed and comprehended the contents aforementioned',
  connect_to_orderly_account:
    "You need to (re)connect your Orderly account to use Ref's Orderbook.",
  first_register_orderly_tip:
    'Your wallet must first be registered with Orderly in order to use the Orderbook.',
  must_register_tip:
    'Your wallet must be registered with Orderly to trade on their system.',
  register_deposit_tip: 'Registering will require a storage deposit',
  increase_storage_deposit:
    'You may need to increase the storage deposit on your Orderly account.',
  deposit_storage_fee: 'Deposit storage fee',
  register_orderly_account: 'Register Orderly Account',
  register: 'Register',
  connect_to_orderly: 'Connect to Orderly',
  order_cancelled: 'Order Cancelled.',
  order_edit_success: 'Order Edit Successfully!',
  created_orderly: 'Created',
  open_orderly: 'Open',
  dex: 'Dex',
  min_price_should_be_higher_than_or_equal_to:
    'Min price should be higher than or equal to',
  price_should_be_lower_than_or_equal_to:
    'Price should be lower than or equal to',
  price_should_be_a_multiple_of: 'Price should be a multiple of',
  price_should_be_greater_than_or_equal_to:
    'Price should be greater than or equal to',
  the_order_value_should_be_be_greater_than_or_equal_to:
    'The order value should be greater than or equal to',
  quantity_to_buy_should_be_greater_than_or_equal_to:
    'Quantity to buy should be greater than or equal to',
  quantity_to_sell_should_be_greater_than_or_equal_to:
    'Quantity to sell should be greater than or equal to',
  quantity_to_buy_should_be_less_than_or_equal_to:
    'Quantity to buy should be less than or equal to',
  quantity_to_sell_should_be_less_than_or_equal_to:
    'Quantity to sell should be less than or equal to',
  quantity_should_be_a_multiple_of: 'Quantity should be a multiple of',
  at_least_one_of_order_quantity_or_price_has_to_be_changed:
    'At least one of order quantity or price has to be changed',
  quantity_should_be_higher_than: 'Quantity should be higher than',
  price_should_be_higher_than: 'Price should be higher than',
  insufficient_en: 'Insufficient',
  the_all_data_orderly_tip:
    'The data provided herein includes all assets and records in your account, not limited to those generated through REF.',

  at_orderly: 'at',

  change_the_quantity_of: 'Change the quantity of',
  change_the_price_of: 'Change the price of',
  market_order_filled: 'Market Order Filled',
  limit_order_created: 'Limit Order Created',
  limit_order_filled: 'Limit Order Filled',
  open: 'Open',
  to_buy: 'To Buy',
  to_sell: 'To Sell',
  in_open_orders: 'In Open Orders',
  your_investment: 'Your Investment',
  your_investment_tip:
    'USD value of your investment on Ref:Classic pools + DCL pools (including staked in farms) + xREF',
  classic_pools: 'Classic',
  xref_staking: 'xREF Staking',
  token_balances: 'Token Balances',
  unclaimed_earnings: 'Unclaimed Earnings',
  unclaimed_earnings_tip:
    'USD value of unclaimed fees from DCL pools, and unclaimed farm rewards.',
  unclaimed_pool_fees: 'Unclaimed Pool Fees',
  unclaimed_farm_rewards: 'Unclaimed Farm Rewards',
  active_orders: 'Active Orders',
  yield_farming: 'Yield Farming',
  execute_status: 'Execute Status',
  your_liquidity_usd_value: 'Your Liquidity (USD value)',
  usage: 'Usage',
  holding: 'Holding',
  your_lp_tokens_and_shares: 'Your LP Tokens(Shares)',
  usd_value_staked: 'USD Value Staked',
  instant_swap: 'Instant Swap',
  swapped: 'Swapped',
  created_s: 'created',
  order_progress: 'Order Progress',
  dcl_pool: 'DCL Pool',
  classic: 'Classic',
  classic_farms: 'Classic Farms',
  unstaked: 'Unstaked',
  staked_in_farm: 'Staked in farm',
  farm_reward_range: 'Farm Reward Range',
  your_positions: 'Your Positions',
  your_price_range: 'Your Price Range',
  locked_in: 'locked in',
  near_locked_in_wallet_for_covering:
    '0.5 NEAR locked in wallet for covering transaction fees',
  account_assets: 'Account Assets',
  open_qty: 'Open Qty.',
  actions_orderly: 'Actions',
  records: 'Records',
  buy_pop: 'Buy',
  sell_pop: 'Sell',
  fee_orderly: 'Fee',
  executing: 'Executing',
  from_2: 'from',
  to_2: 'to',
  your_orders_2: 'Your Orders',
  in_farm_2: 'in farm',
  your_liquidity_2: 'Your Liquidity',
  welcome: 'Welcome',

  welcome_mobile: 'Connect your wallet to start',
  select_token_orderly: 'Select Token',
  overview: 'Overview',
  positions_2: 'Positions',
  'deposit_near_tip_0.5': 'To deposit, keep at least 0.5 NEAR to cover gas fee',
  trade: 'Trade',
  earn_2: 'Earn',
  portfolio: 'Portfolio',
  more_2: 'More',
  liquidity_pools_2: 'Liquidity Pools',
  business_inquiries: 'Business Inquiries',
  stable_pools: 'Stable',
  pool_detail: 'Pool Detail',
  new_contract_deploy_tip:
    'A new contract has been deployed! Please remove your liquidity from the old contract',
  remove_will_automatically_claim:
    'Removing will automatically claim your unclaimed fees.',
  legacy_tip: 'Found unstaked LP tokens or rewards in',
  legacy_farms: 'Legacy Farms',
  all_farms: 'All Farms',
  your_farms_2: 'Your Farms',
  dcl_farms: 'DCL Farms',
  farm_type: 'Farm type',
  farm_2: 'Farm',
  to_claim: 'To Claim',
  liquidity_staked: 'Liquidity staked',
  dcl_pool_detail: 'DCL Pool Detail',
  reward_range: 'Reward Range',
  reward_range_tip: 'Farm reward within this range',
  ending_soon: 'Ending soon',
  farm_ended_tip: 'This farm has ended.',
  farm_will_ended_tip: 'This farm will end soon.',
  new_farm: 'New Farm',
  is_coming: 'is coming',
  est_apr: 'Est. APR',
  state_2: 'State',
  farming: 'Farming',
  unavailable: 'Unavailable',
  liquidity_detail: 'Liquidity Detail',
  hide: 'Hide',
  show: 'Show',
  unavailable_positions: 'unavailable positions',
  your_price_range_tip: 'Your price range is out of reward range',
  position_has_staked_tip: 'This position has been staked in another farm',
  minimum_tip: 'The minimum staking amount is',
  your_liquidity_3: 'your liquidity',
  position_has_staked_pre: 'This position has been staked in',
  another_farm: 'another farm',
  add_position: 'Add Position',
  full_range: 'Full Range',
  your: 'Your',
  max_apr: 'Max.APR',
  faming_positions: 'Farming Positions',
  you_can_earn_tip: 'Farm available, farm APR up to',
  you_can_earn_current_tip:
    'Your current staked farm ended, and new farm is coming, est. APR is',
  go_new_farm: 'Go New Farm',
  go_farm: 'Go Farm',
  earn_rewards: 'You can earn rewards',
  est_apr_is: 'est. APR is',
  new_farm_2: 'new farm',
  you_can_earn_current_pre_tip: 'Your current staked farm ended, and',
  in_ended_farm: 'in ended farm',
  staked: 'Staked',
  in_farm_3: 'in farm',
  estimation: 'Estimation Value',
  no_dcl_position_tip:
    "You don't have any DCL Liquidity position for now, click 'AddPosition' to start farming.",
  price_on_slot_tip: 'The price should be in one slot nearby.',
  position_will_appear_here: 'Your liquidity positions will appear here.',
  by_farming: 'by farming',
  this_staked_tip: 'This NFT has been staked',
  dcl_liquidity: 'DCL Liquidity',
  classic_liquidity: 'Classic Liquidity',
  will_appear_here_tip: 'Your liquidity positions will appear here.',
  dcl_will_appear_here_tip: 'Your DCL liquidity positions will appear here.',
  classic_will_appear_here_tip:
    'Your Classic liquidity positions will appear here.',
  yield_farming_appear_here_tip: 'Your yield farming will appear here.',
  active_order_appear_here_tip: 'Your active order(s) will appear here.',
  account_appear_here_tip: 'Your wallet/account assets will appear here.',
  farm_state: 'Farm State',
  farm_detail: 'Farm Detail',
  unstaked_2: 'Unstaked',
  unstaked_positions: ' Unstaked Positions',
  price_range_overlap_ratio: ' Price Range Overlap Ratio',
  swapped_history: 'swap history',
  real_time_executed_orders: 'real-time executed orders',
  real_time_executed_orders_tip:
    'When placing a sell order above market price or a buy order below market price using limit order function, Ref will attempt to execute a swap-with-stop-point action first, and if there is any remaining amount, a limit order will be created to fill the rest.<br> Real-time executed orders refer to orders placed using the limit order function but executed through swapping. In the "History" section, you can view real-time executed orders between the earliest displayed limit order in History up to the present time.',

  swapped_history_order: 'Swapped',
  ledger_guide_tip_1:
    'To successfully use your Ledger wallet for transactions, the ',

  ledger_guide_tip_2: 'feature has been automatically activated.',
  ledger_guide_tip_3: 'Please be aware',
  ledger_guide_tip_quote: '"',

  support_ledger_tip_new: 'Support Ledger',
  ledger_guide_tip_4:
    'that due to Ledger wallet constraints, the current rate provided by the swap function',
  ledger_guide_tip_5: 'may not be the best price',
  ledger_guide_tip_6: '.',
  view_dcl_pool: 'View DCL Pool',
  your_trade_route: 'Your Trade Route',
  markets: 'Markets',
  exchanges: 'Exchanges',
  output_est: 'Output (est.)',
  go_to_orderbook: 'Go to Orderbook',
  new_swap_order_tip:
    'The price is from the Ref AMM and for reference only. There is no guarente that your limit order will be immediately filled. ',
  instants_swap: 'Instant Swap',
  swappped: 'Swapped',
  claimed: 'Claimed',
  executing_capital: 'Executing',
  record: 'Record',
  trade_route: 'Trade Route',
  settings: 'Settings',
  cross_chain_options: 'Cross-chain Options',
  no_trade_routes: 'No Trade Routes',
  steps_in_the_route: 'Steps in the Route',
  swap_chart_no_data: 'Not enough data for the chart right now.',
  go_to_orderbook_tip:
    'This price is for reference only. {br} Please proceed to <strong>Orderbook</strong> to place the order.',
  last_updated: 'Last Updated',
  steps_in_the_route_zh: '',
  has_tri_pool_tip:
    ' in Ref. Enable Trisolaris in Cross-chain Options to find more.',

  Overview: 'Overview',
  RefFinance: 'Ref.finance',
  Orderly: 'Orderly',
  Burrow: 'Burrow',
  Supplied: 'Supplied',
  Borrowed: 'Borrowed',
  NetAPY: 'Net APY',
  UnclaimedRewards: 'Unclaimed Rewards',
  YouSupplied: 'You Supplied',
  SupplyMarket: 'Supply Market',
  YouBorrowed: 'You Borrowed',
  BorrowMarket: 'Borrow Market',
  NetAPYTip:
    'Net APY of all supply and borrow positions, including base APYs and incentives',
  Market: 'Market',
  TotalSupplied: 'Total Supplied',
  TotalBorrowed: 'Total Borrowed',
  AvailableLiquidities: 'Available Liquidity',
  DailyRewards: 'Daily Rewards',
  SupplyAPY: 'Supply APY',
  BorrowAPY: 'Borrow APY',
  Collateral: 'Collateral',
  Adjust: 'Adjust',
  Supply: 'Supply',
  Repay: 'Repay',
  Borrow: 'Borrow',
  HealthFactor: 'Health Factor',
  CollateralFactor: 'Collateral Factor',
  AdjustCollateral: 'Adjust Collateral',
  UseAsCollateral: 'Use as Collateral',
  RemainingCollateral: 'Remaining Collateral',
  RemainingBorrow: 'Remaining Borrow',
  NoSupplyTip: 'Your supplied assets will apear here.',
  NoBorrowTip: 'Your borrowed assets will apear here.',
  APY: 'APY',
  CF: 'C.F.',
  burrowErrorTip:
    'The current balance is below the minimum token decimals, so that it cannot be processed by the contract.',
  healthErrorTip:
    "Your health factor will be dangerously low and you're at risk of liquidation.",
  Summary: 'Summary',
  Welcome: 'Welcome!',
  ConnectView: 'Connect your wallet to view',
  TotalSuppliedMarket: 'Total Supplied',

  NetWorth: 'Net Worth',
  Claimable: 'Claimable',
  TotalDebts: 'Total Debt',
  WalletAssets: 'Wallet Assets',
  TotalInvested: 'Total Invested',
  TotalAssets: 'Total Assets',
  TokenAllocation: 'Token Allocation',
  perpTip:
    'Welcome to Perpetual Futures trading! Please read {perpTipLink} to get started!',
  perpTipLink: '[Perpetual Futures Introduction]  ',
  perpetual: 'Perpetual',
  pred_funding_rate: 'Pred. Funding Rate',
  open_interest: 'Open Interest',
  bridge_pure: 'Bridge',
  pool_refresh: 'Something wrong with the server, please try again later.',
  netWorthTip:
    'Total value of investments in Ref (including claimable rewards) + Total value of Orderly assets + Total value of Burrow assets + Total assets in wallet',
  top_bin_apr: 'Top Bin APR (24h)',

  mark_price: 'Mark Price',
  last_price: 'Last Price',
  mark_price_tip: 'Mark price is used for PnL calculating and liquidation.',
  total_collateral: 'Total Collateral',
  current_leverage: 'Current Leverage',
  free_collateral: 'Free Collateral',
  total_upnl: 'Total uPnL',
  perps: 'Perps',
  margin_ratio: 'Margin Ratio',
  total_npnl_tip:
    'Estimated unrealized profit and loss across all open positions',
  low_risk: 'Low Risk',
  mid_risk: 'Mid Risk',
  high_risk: 'High Risk',
  liquidations: 'Liquidations',
  leverage_max_leverage: 'Max Account Leverage',
  leverage_max_leverage_raw: 'Max Account Leverage',
  marker_taker_fee_rate: 'Maker/Taker Fee Rate',
  liquidation_price: 'Liquidation Price',
  margin_required: 'Margin Required',
  perp_buy_limit_order_range:
    'The price of buy limit orders should be less than or equal to',
  perp_sell_limit_order_range:
    'The price of sell limit orders should be greater than or equal to',
  perp_buy_limit_order_scope:
    'The price of a buy limit order cannot be lower than',
  perp_sell_limit_order_scope:
    'The price of a sell limit order cannot be higher than',
  liquidations_fee: 'Liquidations Fee',
  more_trading_price: 'More Trading Price',
  unsettle_pnl_tip:
    'Move a profit or loss from perp markets into the USDC.e token balance. This has no impact on your open positions or health.',
  margin_ratio_tip:
    'The margin ratio of an account is equal to the total collateral value (ie the USDC.e balance + any unrealized profit/loss) divided by the total open notional of the account (the sum of the absolute notional value of all positions)',
  portfolio_total_est_tip: 'Portfolio value in USD.',
  size: 'Size',
  closing_1: 'Closing',
  closing_2: 'position at {type} price.',
  buy_long: 'Buy / Long',
  sell_short: 'Sell / Short',
  unsettle_pnl: 'Unsettle PnL',
  the_margin_will_be_insufficient: 'The margin will be insufficient',
  exceed_rate_limit:
    'You have exceeded the rate limit, please try again in 60 seconds',
  est_liquidation_price: 'Est. Liquidation Price',
  liquidation_price_tip:
    'This price is for reference only. You can see the liquidation price in your Orderly portfolio after your order is filled.',
  futures_leverage_saved: 'Futures leverage saved',
  maintenance_margin_ratio: 'Maintenance Margin Ratio',
  liquidation_fee: 'Liquidation Fee',
  ins_fund_transfer: 'Ins. Fund Transfer',
  no_liquidation_yet: 'No liquidation yet',
  funding_fee: 'Funding Fee',
  settlements: 'Settlements',
  funding_annual_rate: 'Funding Rate / Annual Rate',
  insufficient_margin: 'Insufficient Margin',
  taker_maker_fee: 'Taker/Maker Fee',
  taker_maker_fee_rate: 'Taker/Maker Fee Rate',
  spot_nav: 'Spot',
  perps_nav: 'Perps',
  set: 'Set',
  to_begin_your_trading_journey: 'to begin your trading journey.',
  max_leverage_tip:
    'Max account leverage cannot be lower than your current leverage.',
  orderly_new_usr_spot_tip: 'Deposit assets to begin your trading journey.',
  orderly_new_user_tip:
    'Check Balance and deposit <strong>USDC</strong>  to begin your trading journey.',

  deposit_on_tip: 'Deposit',
  all_dexes: 'All Dexes',
  display_all_orders_dexes:
    'Display orders placed through all channels on Orderly.',
};
export default Object.assign(en_US, en_US_in_risks_page);
